import React, {useEffect} from "react"
import {navigate} from "gatsby"

const InTreatmentHigh = () => {
  useEffect(() => {
    // Update the document href using the gatsby API
    navigate('/treatment/non-high-risk-neuroblastoma/');
  },[]);
  return (
    <></>
  )
}

export default InTreatmentHigh
